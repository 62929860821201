import { useRef } from 'react'

export default function useDebounce(delay: number, callback: Function) {
  const searchInterval = useRef(null)
  const handleClearInterval = () => clearInterval(searchInterval.current)

  const handleTriggerFunction = () => {
    handleClearInterval()
    searchInterval.current = setTimeout(() => {
      callback()
    }, delay)
  }

  return handleTriggerFunction
}
