import { AnimatePresence, motion, useAnimate, usePresence } from 'framer-motion'
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { MagnifyingGlassIcon } from '@library/_form/input-field/_icons/magnifying-glass-icon'
import CloseIcon from '@ui/icons/close'
import Button from '@ui/cta/button'
import { clsx } from 'clsx'
import { generateHTMLId } from '@utils/motion'
import { AnimationsStatesEnum } from '@shared/enums/layout/animations-states.enum'
import { isEmptyString } from '@utils/strings'
import { ONE_SECOND_MS } from '@root/constants'
import { useAppProvider } from '@providers/app-provider/_contexts'
import {
  SEARCH_LOCAL_STORAGE_PREV_URL_NAME,
  SEARCH_PAGE_PATH,
} from '@shared/constants/search.constants'
import { getLocalStorage } from '@utils/localStorage'
import { getCurrentUrl } from '@utils/get-current-url'
import useRedirect from '@hooks/use-redirect'
import Link from 'next/link'
import useDebounce from '@hooks/use-debounce'
import { SearchRequest } from '@shared/interfaces/search.interface'
import useSearch from '@hooks/_search/use-search'

export interface Props {
  label: string
  onSearch: (search: SearchRequest) => void
}

export default function SearchButton({ label, onSearch }: Props): ReactElement {
  const { searchQuery, category } = useAppProvider()
  const { buildSearchUrl } = useSearch()

  // States
  const [searchState, setSearchState] = useState<AnimationsStatesEnum | undefined>(undefined)

  // Ref
  const inputRef = useRef<HTMLInputElement>(null)

  // Framer motion animate hook
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  // Handlers
  const handleOnSearch = () => {
    const word = inputRef.current.value as string
    if (!isEmptyString(word)) {
      onSearch({ word, category })
    }
  }
  const handleDebounce = useDebounce(ONE_SECOND_MS, handleOnSearch)

  // Toggle search button
  const handleCloseSearch = () => setSearchState(AnimationsStatesEnum.HIDE)
  const handleOpenSearch = () => setSearchState(AnimationsStatesEnum.ACTIVE)

  // Elements IDs
  const OPEN_BUTTON = '#open-button'
  const SEARCH_WRAPPER = '#search-wrapper'
  const MAGNIFY_ICON = '#magnify-icon'
  const CLOSE_ICON = '#close-icon'

  // Trigger animations when searchState change
  useEffect(() => {
    if (searchState === AnimationsStatesEnum.ACTIVE && isPresent) {
      const enterAnimation = async () => {
        await animate(OPEN_BUTTON, {
          opacity: 0,
          x: -40,
        })
        await animate(SEARCH_WRAPPER, { opacity: 1 })
        animate(MAGNIFY_ICON, { opacity: 1 })
        animate(CLOSE_ICON, { opacity: 1 })
        inputRef.current.focus()
      }
      enterAnimation()
    } else if (searchState === AnimationsStatesEnum.HIDE) {
      const hideAnimation = async () => {
        animate(MAGNIFY_ICON, { opacity: 0 })
        await animate(CLOSE_ICON, { opacity: 0 })
        await animate(OPEN_BUTTON, { opacity: 1, x: 0 })
        if (!!inputRef.current) {
          inputRef.current.value = ''
        }
        setTimeout(safeToRemove, 1000)
      }
      hideAnimation()
    }
  }, [searchState, isPresent])

  useEffect(() => {
    if (!isEmptyString(searchQuery)) {
      inputRef.current.value = searchQuery
      setSearchState(AnimationsStatesEnum.ACTIVE)
    }
  }, [searchQuery])

  // Elements initial and properties for animations
  const iconsDefaultState = {
    initial: { opacity: 0 },
  }

  const searchIconAnimations = {
    ...iconsDefaultState,
    variants: {
      hover: {
        scale: 1.2,
        transition: { duration: 0.5 },
      },
      default: {
        scale: 1,
      },
    },
  }

  const isActive = useMemo(() => {
    return searchState === 'active'
  }, [searchState])

  const buttonAnimations = {
    animate: {
      transitionEnd: {
        display: isActive ? 'none' : 'flex',
      },
    },
  }

  const inputAnimations = {
    initial: {
      opacity: 0,
      display: 'none',
    },
    animate: {
      transitionEnd: {
        display: isActive ? 'flex' : 'none',
      },
    },
  }

  const mobilePath = useMemo(() => {
    return buildSearchUrl({ word: '', category })
  }, [category])

  return (
    <div ref={scope} className={clsx({ [styles.searchButton]: true, [styles.active]: isActive })}>
      <AnimatePresence>
        <div className={styles.content}>
          <motion.div
            id={generateHTMLId(OPEN_BUTTON)}
            className={styles.wrapperButton}
            layout
            {...buttonAnimations}
          >
            <Button
              variant={'greyVariant'}
              label={label}
              onClick={handleOpenSearch}
              className={styles.searchBtn}
              icon={<MagnifyingGlassIcon />}
            />
            <Link href={mobilePath}>
              <a className={styles.searchIcon}>
                <MagnifyingGlassIcon />
              </a>
            </Link>
          </motion.div>
          <motion.div
            id={generateHTMLId(SEARCH_WRAPPER)}
            className={styles.search}
            layout
            {...inputAnimations}
          >
            <motion.div
              id={generateHTMLId(MAGNIFY_ICON)}
              {...iconsDefaultState}
              className={clsx(styles.icon, styles.magnify)}
            >
              <MagnifyingGlassIcon />
            </motion.div>
            <input ref={inputRef} onChange={handleDebounce} />
            <motion.button
              whileHover={'hover'}
              onClick={handleCloseSearch}
              id={generateHTMLId(CLOSE_ICON)}
              {...searchIconAnimations}
              className={clsx(styles.icon, styles.close)}
            >
              <CloseIcon />
            </motion.button>
          </motion.div>
        </div>
      </AnimatePresence>
    </div>
  )
}
