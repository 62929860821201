import { IconProps } from '@library/_types'
import { isEmptyString } from '@utils/connascence'

export default function CloseIcon({ color }: IconProps): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70712 19.7071C5.31659 20.0976 4.68343 20.0976 4.2929 19.7071C3.90238 19.3166 3.90238 18.6834 4.2929 18.2929L10.5858 12L4.29289 5.70711Z"
        fill={!isEmptyString(color) ? color : 'url(#paint0_linear_2082_496)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2082_496"
          x1="20"
          y1="12"
          x2="3.08778"
          y2="7.24344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
