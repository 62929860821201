import { BASIC_AUTH_TOKEN } from '@root/constants'
import axios from 'axios'
import { APIDETAILS } from '@apis/_utilities/environment/api.url.details'
import { isEmptyString } from '@utils/strings'
import { SearchRequest } from '@shared/interfaces/search.interface'

/**
 * Created to search in server side
 * @param search
 */
export default async function searchSSR(search: SearchRequest) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: BASIC_AUTH_TOKEN,
    'endpoint-version': '2',
  }

  const { word, category } = search

  if (isEmptyString(word)) {
    return {
      contentResults: [],
      channelResults: [],
    }
  }
  const requestUrl = `${APIDETAILS.analyticsUrl}/search/${word}`
  const queryParams = !isEmptyString(category) ? { category } : {}
  return await axios
    .get(requestUrl, {
      headers,
      params: queryParams,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      return {
        contentResults: [],
        channelResults: [],
      }
    })
}
