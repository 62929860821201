import { getLocalStorage, setLocalStorage } from '@utils/localStorage'
import { useRouter } from 'next/router'
import { useAppProvider } from '@providers/app-provider/_contexts'
import { getCurrentUrl } from 'utils/get-current-url'
import useRedirect from '@hooks/use-redirect'
import {
  SEARCH_LOCAL_STORAGE_PREV_URL_NAME,
  SEARCH_PAGE_PATH,
} from '@shared/constants/search.constants'
import searchSSR from '@apis/search/search-ssr'
import { isEmptyString } from '@utils/strings'
import { SearchRequest } from '@shared/interfaces/search.interface'

export default function useSearch() {
  const router = useRouter()
  const currentPage = router.pathname
  const showSearchButton = !currentPage.includes(SEARCH_PAGE_PATH)

  const { handleOnSearch, toggleIsLoading, handleCategory, isLoading, referer } = useAppProvider()
  const { redirectTo, redirectShallow } = useRedirect()

  const buildSearchUrl = (search: SearchRequest) => {
    const { word, category } = search
    return !isEmptyString(category)
      ? `/${SEARCH_PAGE_PATH}?category=${category}&q=${word}`
      : `/${SEARCH_PAGE_PATH}?q=${word}`
  }

  const redirectToSearch = (search: SearchRequest) => {
    const { word, category } = search
    // Setting variables in Provider
    handleOnSearch(word)
    handleCategory(category)
    const currentUrl = getCurrentUrl()
    const searchUrl = buildSearchUrl(search)

    if (currentUrl.includes(SEARCH_PAGE_PATH)) {
      redirectShallow(searchUrl)
    } else {
      toggleIsLoading(true)
      setLocalStorage('prevSearchUrl', currentUrl ?? '/')
      redirectTo(searchUrl)
    }
  }

  const handleSearch = async (search: SearchRequest) => {
    return await searchSSR(search)
  }

  return { redirectToSearch, handleSearch, showSearchButton, buildSearchUrl }
}
