import { NavigationInterface } from '@shared/interfaces/layout/navigation.interface'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import { HELP_VIDZING_EMAIL_LINK, HELP_VIDZING_LINK } from '@shared/constants/root.constants'

export const HEADER_NAVIGATION: NavigationInterface[] = [
  {
    title: 'Creators',
    isAccordion: true,
    menu: [
      {
        label: 'Content Creators',
        path: '/content-creator',
        external: false,
      },
      {
        label: 'Educators',
        path: '/educator',
        external: false,
      },
      {
        label: 'Live Streaming',
        path: '/live-streaming',
        external: false,
      },
      {
        label: 'Business Channels',
        path: '/business-channels',
        external: false,
      },
      {
        label: 'Events',
        path: '/creator/pricing#one-off-events',
        external: false,
      },
    ],
  },
  {
    title: 'Company',
    isAccordion: true,
    menu: [
      {
        label: 'Help',
        path: HELP_VIDZING_EMAIL_LINK,
        external: false,
      },
      {
        label: 'House Rules',
        path: '/house-rules',
        external: false,
      },
      {
        label: 'Contact Us',
        path: HELP_VIDZING_EMAIL_LINK,
        external: false,
      },
      {
        label: 'Blog',
        path: UrlPathsEnum.BLOG,
        external: true,
      },
    ],
  },
  // {
  // title: 'Creator',
  // isAccordion: true,
  // menu: [
  //  {
  //    label: 'Become a Creator',
  //    path: '/creator/signup',
  //   external: false,
  //   },
  // TODO: No more hubspot redirect to new blog hosting when ready
  // {
  //   label: 'Blog',
  //   path: '',
  //   external: true,
  // },
  // {
  //   label: 'Case Studies',
  //   path: '',
  //   external: true,
  // },
  // ],
  // },
  {
    title: 'Pricing',
    path: UrlPathsEnum.CREATOR_PRICING,
    menu: [],
    isAccordion: false,
  },
  {
    title: 'Explore',
    path: UrlPathsEnum.EXPLORE,
    menu: [],
    isAccordion: false,
  },
]

// Text Constants
export const GET_STARTED_TEXT = 'Get started'
export const LOGIN_TEXT = 'Login'
export const FIND_A_CREATOR_TEXT = 'Find a creator'
