import { useRouter } from 'next/router'

export default function useRedirect() {
  const router = useRouter()

  const redirectTo = (url: string) => {
    router.push(url)
  }

  const redirectShallow = (url: string) => {
    router.replace(url, '', { shallow: true })
  }

  return { redirectTo, redirectShallow }
}
