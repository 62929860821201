import style from './common.module.css'
import { ChannelThemeInterface } from '@shared/interfaces/creators/channel-theme.interface'

export interface Props {
  channelTheme?: ChannelThemeInterface
}

export function Loader({ channelTheme }: Props): JSX.Element {
  const cssVariables: Record<string, unknown> = {
    '--color': channelTheme ? channelTheme.buttonFontColour : 'white',
  }
  return (
    <div className={style.container}>
      <div className={style.dot} style={cssVariables} />
      <div className={style.dot} style={cssVariables} />
      <div className={style.dot} style={cssVariables} />
    </div>
  )
}
