import style from './index.module.css'

export type Props = {
  variant?: 'footer' | 'other'
}

export default function CopyRight({ variant = 'footer' }: Props): JSX.Element {
  const year = new Date().getFullYear()
  return <span className={style[variant]}>Copyright © {year} Vidzing</span>
}
